*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'Poppins', sans-serif;
}

.container-top{
    content: '';
    /* background-color: rgb(203, 224, 235); */
    /* background: url("./oxy_assets/pexels-pixabay-235985.jpg"); */
    /* position:absolute;
    top:0px;
    left:0px; */
    padding: 0 130px
    
}
.main-input{
    /* display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column; */
    /* background: #007bff; */
    /* height: 100vh; */

}
.main-input-logo{
  display:flex;
  justify-content: space-between;
}
.main-input-logo-photo {
  max-width: 278px;
}
.main-input-logo-photo img{
  width:100%;
  height: 100%;
  object-fit: cover;
}
/* .new-form{
 
    display:flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
} */
.form-set{
    /* max-height: 100vh; */
    /* max-width:500px; */
    /* max-width: 694px; */


    /* max-width: 848px; */
    
    background-color:white;
    padding: 30px;
    border-radius: 10px;
}
.flexBox-inner{

    /* width:100%; */
    /* margin:10px */
        margin-bottom: 30px;
}
/* .flex-common{
    margin-top: 30px;
} */
.flexBox-inner label{
    font-size: 18px;
    color:rgb(65, 75, 85)
    /* margin-bottom:30px; */

}
input{
    width:100%
}
.fullInput{
    padding: 0px 2px;
    font-size: 15px;
    letter-spacing: 1px;
    border: transparent;
    background-color: transparent!important;
    border: 2px solid #ddd;
    border-top: transparent;
    border-right: transparent;
    border-left: transparent;
   /* margin-top: 10px; */
}
.phone-idea,.PhoneInputInput{

  
    padding: 2px 2px;
    font-size: 15px;
    letter-spacing: 1px;
    border: transparent;
    background-color: transparent!important;
    /* border: 2px solid #ddd;
    border-top: transparent;
    border-right: transparent;
    border-left: transparent; */
   /* margin-top: 10px; */
}
.fullInput{
    /* margin-top: 2px; */
    /* margin-top: 9px; */
    margin-top :4px;;
}
/* .PhoneInputCountrySelectArrow{
    margin-top: 10px;

} */
/* .PhoneInputCountryIcon, */
.PhoneInput{
    /* margin-top: 6px; */
    margin-top: 0px;

    background-color: transparent!important;
    border: 2px solid #ddd;
    border-top: transparent;
    border-right: transparent;
    border-left: transparent;
}

/* .fullInput{
    min-width: 60%;
  
    padding: 30px;
    border:none;
    margin: none;
    border-radius: 8px 0px 0px 8px;
    font-size: 16px;


} */
textarea:focus, input:focus{
    outline: none;
}
.search-config{
    border: none;
    padding: 0px 10px;
    background-color:#6E88D2;
    color:white;
    font-size: 15px;
    font-weight: 500;
    /* border-radius: 0px 8px 0px 0px; */
    border-radius: 8px;
    width:100%;
    /* margin-top: 15px; */
    margin-top: 30px;
    cursor: pointer;
    height:46px;
}
/* .container-top{
    padding: ;
} */
.loadingParent{

    color: white;
    /* background: red; */
    padding: 15px;
    z-index: 10000;
    /* position: absolute; */
    position: fixed;

    top: 50%;
    left: 50%;
    -ms-transform: translateX(-50%) translateY(-50%);
    -webkit-transform: translate(-50%,-50%);
    transform: translate(-50%,-50%);
    display:none
   
  }
  .active{
    -webkit-filter: brightness(50%) !important;
  }
.disabled-btn{

   /* background-color:#221b1b */
   background-color: rgb(65, 75, 85);
   cursor: text;
}
.flex-common .relative{
  position: realative
}
.formerror{
  position:absolute;

  margin-top: 0.25rem;
}
.formerror p{
  font-size: .875em;
    color: #dc3545
}

.lds-ripple {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;
  }
  .lds-ripple div {
    position: absolute;
    border: 4px solid #000;
    opacity: 1;
    border-radius: 50%;
    animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
  }

    .switch-dark  .lds-ripple div {
      border: 4px solid #fff;
    }
  .spanMandotory{
    font-size: 15px;
    font-weight: 700;
  }
  .lds-ripple div:nth-child(2) {
    animation-delay: -0.5s;
  }
  @keyframes lds-ripple {
    0% {
      top: 36px;
      left: 36px;
      width: 0;
      height: 0;
      opacity: 0;
    }
    4.9% {
      top: 36px;
      left: 36px;
      width: 0;
      height: 0;
      opacity: 0;
    }
    5% {
      top: 36px;
      left: 36px;
      width: 0;
      height: 0;
      opacity: 1;
    }
    100% {
      top: 0px;
      left: 0px;
      width: 72px;
      height: 72px;
      opacity: 0;
    }
  }


  @media (max-width: 1199px){

.container-top{
  padding:0px 90px
}
  }
  @media (max-width: 991px){

    .container-top{
      padding:0px 50px
    }
      }
      @media (max-width: 767px){
        .container-top{
          padding:0px
        }
      }
      .container-dash{
        padding:20px 15px 0px;
        
      }
      @media (min-width: 1400px){


        .container-need{
          /* align-self: center; */
          display: flex;
          align-items: center;
          justify-content: center;
          height: 100vh;
      }
      }



      /* @media screen and (max-width:1025px) and (min-width:991px){
        .container-need{
          height: 100vh;
          display: flex;
          align-items: center;
          justify-content: center;
          margin: 0;
        }
      } */

  

  /* ............toggle_button............. */



  /* .switchertoggle.switch-dark{ */
  .switch-dark{

    background: #3D4144;
}
   
   .switchertoggle .darktoggle-btn {
  width: 70px;
  height: 32px;
  margin: 10px;
  border-radius: 50px;
  display: inline-block;
  position: absolute;
  background: 50px center #bebebf;
  cursor: pointer;
  -webkit-transition: background-color 0.4s ease-in-out;
  -moz-transition: background-color 0.4s ease-in-out;
  -o-transition: background-color 0.4s ease-in-out;
  transition: background-color 0.4s ease-in-out;
  cursor: pointer;
  top: 20%;
    right: -28px;
    transform: rotate(90deg);
}
.switchertoggle.switch-dark .darktoggle-btn{
  background: 10px center #c9cacb;
}
.switchertoggle.switch-dark .round-btn {
  left: 40px;
}
.darktoggle-btn .round-btn {
  width: 25px;
  height: 25px;
  background-color: #fff;
  border-radius: 50%;
  display: inline-block;
  position: absolute;
  left: 5px;
  top: 50%;
  margin-top: -13px;
  -webkit-transition: all 0.3s ease-in-out;
  -moz-transition: all 0.3s ease-in-out;
  -o-transition: all 0.3s ease-in-out;
  transition: all 0.3s ease-in-out;
}
.switchertoggle .darktoggle-btn .cb-value {
  position: absolute;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  opacity: 0;
  z-index: 9;
  cursor: pointer;
  -ms-filter: "progid:DXImageTransform.Microsoft.Alpha(Opacity=0)";
}
.switch-dark .PhoneInputInput{
  /* margin-top: 6px; */
  /* margin-top: 0px;

  background-color: transparent!important;
  border: 2px solid #ddd;
  border-top: transparent;
  border-right: transparent;
  border-left: transparent; */
  color:#D2DCDE
}

.switch-dark .PhoneInput .PhoneInputCountrySelectArrow{
  color:#D2DCDE
}
.switch-dark  .PhoneInput {

  border: 1px solid #676a6c

}
/* max-width */
.excludedDBalert h5{
font-size: 15px;
}
.excludedDBalert{
  text-align: center;
  display:none
}
.excludedDBalert ul{
  max-width: 100%;
  /* display:flex;
  justify-content: center; */
  flex-align:center;
  /* flex-direction: column; */
  /* text-align: center; */
  list-style-type: none; /* Remove default bullet points */
  padding-left: 20px;
}
.excludedDBalert ul li{
  margin-top: 10px;
  max-width: 100%;
  font-size: 15px;

  
  
}
.excludedDBalert li:before {
  content: "●"; /* Use a bullet point symbol (you can change this to any character or shape you want) */
  color: #525252; /* Set the color of the custom bullet point */
  margin-right: 5px; /* Add space between the custom bullet point and the text */
}


.query-status{
  justify-content: flex-end;
}
.custom-p-custom-checkbox{
  display:flex;
  flex-direction: row;
  justify-content: flex-start;
  gap:20px


  /* width:100% */
}
.custom-p-custom-checkbox input{
  width:auto;
  margin-right: 10px;
}


/* .custom-checkbox{
display: inline-block;
} */
/* @media (max-width: 767px){
  .excludedDBalert li{
    max-width: ;
  }
} */