body {
    /* background: #fff; */
    /* background: #3D4144; */

}

.main-input-logo {
    display: flex;
    justify-content: center;
    border-bottom: 1px solid #BEBEBE;
    margin-bottom: 20px;
}

.container-dash .fullInput {
    height: 46px;
    border: 1px solid #BEBEBE;
    border-radius: 5px;
    padding: 0 10px;
    color: #000;
}

.container-dash .flexBox-inner label {
    color: #525252;
    font-size: 15px;
    font-weight: 400;
    /* font-family: Poppins; */
}

.container-dash .PhoneInput {
    height: 46px;
    border: 1px solid #BEBEBE;
    border-radius: 5px;
    padding: 0 10px;
    color: #000;
    margin-top: 4px;
}

.container-dash .css-nct28o-control {
    border: 1px solid #BEBEBE;
    min-height: 46px;
    border-radius: 5px;
    padding: 0 10px;
    color: #000;
    margin-top: 5px;
}

.container-dash .search-config {
     width: 100%;
    text-transform: uppercase;
    margin: 0 auto;
}

.container-dash .form-set {
    border-radius: 0px;
    padding: 30px 0px;
    background: transparent;
}
.container-dash .disabled-btn {
    background-color: #D9D9D9;
}




/* ai css gulo add kore dio */



.switch-dark .container-dash .flexBox-inner label {
    color: #D2DCDE;
}

.switch-dark .PhoneInputCountryIcon .PhoneInputInternationalIconGlobe{
      stroke: #fff; 
}

.switch-dark .PhoneInputCountryIcon .PhoneInputInternationalIconPhone{
       color: #fff;
}

.switch-dark .container-dash .disabled-btn {
    background-color: #24272c;
}




.switch-dark .container-dash .flexBox-inner label {
    color: #D2DCDE;
}

.switch-dark .PhoneInputCountryIcon .PhoneInputInternationalIconGlobe{
      stroke: #fff; 
}

.switch-dark .PhoneInputCountryIcon .PhoneInputInternationalIconPhone{
       color: #fff;
}

.switch-dark .container-dash .disabled-btn {
    background-color: '#303437';
    color:#A1A6AA
}





.switch-dark .container-dash .fullInput {
    color: #D2DCDE  !important;
}

.round-btn svg:last-child{
    width: 29px;
    height: 24px;
    transform: rotate(98deg);
    margin-left: -5px;
    margin-top: 0px;
}
.round-btn svg:last-child{
  display: none;
}
.switch-dark .round-btn svg:first-child{
  display: none;
}
.switch-dark .round-btn svg:last-child{
  display: block;
}
.round-btn svg:last-child path{
       fill: #fff;
}

.switchertoggle .darktoggle-btn {
    overflow: hidden;
   background: 50px #f3971f !important;
}

.switchertoggle.switch-dark .darktoggle-btn {
    background: 10px #f3971f !important;
}
.darktoggle-btn .round-btn {
    background-color: transparent !important;
margin-top: -15px !important;
    transform: rotate(-99deg);
}
.css-b5ufz-control {
    border: 1px solid #bebebe !important;
}
.switch-dark .fullInput::placeholder{

color:#676a6c
}
.switch-dark .fullInput::-moz-placeholder{
    color:#676a6c
}
.switch-dark .fullInput::-webkit-input-placeholder{
    color:#676a6c

}
.switch-dark .PhoneInputInput::placeholder{

    color:#676a6c
    }
    .switch-dark .PhoneInputInput::-moz-placeholder{
        color:#676a6c
    }
    .switch-dark .PhoneInputInput::-webkit-input-placeholder{
        color:#676a6c
    
    }
    .switch-dark #react-select-3-placeholder{
        color:#676a6c;
        font-size: 15.5px;

    }
    .switch-dark  .container-dash .fullInput {
        border: 1px solid #676a6c;
       
    }
    .switch-dark  .main-input-logo {
        border-bottom:  1px solid #676a6c
       
    }


    @media (max-width: 767px){
    .switchertoggle .darktoggle-btn{
        top:12%
    }
}





/* ......final edge css.......   */



.fullInput::placeholder{
    color:   rgba(82, 82, 82, 0.2); 
    letter-spacing: normal;
    line-height: 18px;
  }
  .PhoneInputInput::placeholder{
      color:   rgba(82, 82, 82, 0.2);
      letter-spacing: normal;
      line-height: 18px;
  }
  /* .container-dash .flexBox-inner label {
     color: rgba(0, 0, 0, 1);
  } */
  .custom-p-custom-checkbox input{
      position: relative;
  }
  .disabled-btn{
      color:#525252 !important;
      
  }
  .database-requred{
      text-align: center;
      /* margin-top: 30px; */
      margin-bottom: 0;
      font-size: 18px;
      font-weight: 400;
      color:#525252;
      margin-bottom: 30px; 

  
  }
  .switchertoggle .darktoggle-btn {
      top: 29%;
      right: -23px;
  }
  
      .custom-checkbox input[type='checkbox']:before {
            z-index: 1;
            width: 100%;
            height: 100%;
            position: absolute;
            top: 0;
            left: 0;
            background-color: #D9D9D9;
            content: "";
            border: 1px solid 
            #000000;
      }
      .custom-checkbox input[type='checkbox']:checked:before {
       background-color: transparent;
      }
  
  
      /* / =====================for night mode==================== / */
      
      .switch-dark .container-dash .disabled-btn {
          color: #a1a6aa !important;
      }
      .switch-dark .database-requred{
          color: #A1A6AA;
  
      }
      .switch-dark .search-config{   
          color: #A1A6AA;
      }
      .switch-dark .fullInput::placeholder{
       color: #8E8E9C;
      }
      .switch-dark .fullInput::-moz-placeholder{
       color: #8E8E9C;
      }
      .switch-dark .PhoneInputInput::-moz-placeholder{
         color: #8E8E9C; 
      }
      .switch-dark .PhoneInputInput::placeholder{
         color: #8E8E9C; 
      }
      .switch-dark .container-dash .disabled-btn {
          background-color: #42474A;
          
      }
      .switch-dark{
          background-color: #161A1D !important;
      }


      .parent-backgrond-img{
        background-repeat: no-repeat;
background-size: contain;
background-position: center;
      }
      /* .container-dash .css-b62m3t-container{
        height: 150px;
     overflow: auto;
 } */
  
 .custom-checkbox input[type='checkbox']:before {
    border-radius: 2px;
}

 @media screen and (max-width:567px){
    .switchertoggle .darktoggle-btn {
      top: 13% !important;
      width: 60px !important;
      height: 25px !important;
      right: 15px !important;
      transform: rotate(0deg) !important;
  }
  .round-btn svg:last-child {
    margin-left: -4px;
    margin-top: -6px;
}
  }
  @media screen and (max-width:400px){
    .switchertoggle .darktoggle-btn {
      top: 16% !important;
  }
  }


  /* .css-17dvp3m::-webkit-scrollbar {
    width: 4px;
  }
  .css-17dvp3m::-webkit-scrollbar-track {
    background: #f1f1f1;
  }
  .css-17dvp3m::-webkit-scrollbar-thumb {
    background: #424242;
  }
  .css-17dvp3m::-webkit-scrollbar-thumb:hover {
    background: #424242;
  } */

